import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import Button from "react-bootstrap/Button";

const AboutPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "life-coaching-hero.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				helen: file(relativePath: { eq: "testimonials/helen-burton.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100)
					}
				}
				luke: file(relativePath: { eq: "testimonials/luke-watts.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100)
					}
				}
				sophie: file(relativePath: { eq: "testimonials/pier-2.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Life Coaching | Jason Cornes";
	const description =
		"When you’re feeling a bit stuck, life coaching can provide a listening ear in a non-judgemental way, understand and help you find your way forward.";
	const heroImg = data.heroImg.childImageSharp.fluid;
	const helen = data.helen.childImageSharp.gatsbyImageData;
	const luke = data.luke.childImageSharp.gatsbyImageData;
	const sophie = data.sophie.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Life Coaching with Jason Cornes",
				item: {
					url: `${siteUrl}/life-coaching`,
					id: `${siteUrl}/life-coaching`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/life-coaching`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Life Coaching with Jason Cornes",
						},
					],
				}}
			/>
			<Hero
				isDarken={true}
				backgroundImage={heroImg}
				title="Invest in Yourself with Life Coaching"
				description1="Helping ambitious individuals overcome challenges and follow their dreams."
			/>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<p>
								Feeling stuck, at a crossroads, in need of a fresh independent
								outside perspective?{" "}
							</p>
							<p>
								We all have friends, family and colleagues who care about us,
								but no matter how well-meaning they are, they all have an
								agenda. They see your situations, your worries or your problems
								through their own lens and with their interests in mind.{" "}
							</p>
							<p>
								So, when you’re feeling a bit stuck, wouldn’t it be helpful to
								have someone on hand to listen in a non-judgemental way, with no
								vested interests, trained to listen, understand and help you
								find your way forward?
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<h2 className="pb-3">Solution focussed life coaching</h2>
							<p>
								Now, I expect you’re here because you’re an ambitious, focussed
								and outcome-driven individual, wanting to make some positive
								changes, searching for a solution to something that’s causing
								difficulty or stress in your life.{" "}
							</p>
							<p>
								Often the desire for life coaching can be triggered by a change
								that has taken place in your life such as redundancy, moving to
								a new area or job, bereavement or other loss, an inheritance or
								the end of a relationship. Or perhaps an exciting opportunity
								has offered itself and you are not sure what to do next.
							</p>
							<p>
								To the outside world, it may seem like you’ve ‘got it together’,
								and your life is perfect. But for your own individual reasons
								and circumstances, you’re still not completely happy or
								fulfilled.{" "}
							</p>
							<ul>
								<li>Feeling stuck and unsure how to move forward?</li>
								<li>Would you like to turn a dream into a reality?</li>
								<li>
									Could you benefit from a boost to your self-confidence or
									self-esteem?
								</li>
								<li>
									Need to resolve or recover from something that’s causing you
									stress or anxiety?{" "}
								</li>
								<li>
									Is ‘Imposter syndrome’ or a lack of self-belief getting in the
									way?
								</li>
								<li>
									Feel ‘Enough is Enough’ and now is the time to make positive
									change happen?
								</li>
								<li>
									At a crossroads in your professional or home life and
									wondering which road to take?
								</li>
								<li>Wanting to develop some positive habits?</li>
								<li>Ready for a fresh start?</li>
							</ul>
							<p>
								Whatever it may be, and however you’re feeling right now,
								whether you’re sad, happy, angry, frustrated, enthusiastic,
								optimistic, or even a mixture of all these, you’ll be feeling
								stuck. At a crossroads, looking to find a pathway through a
								challenge or to make a dream come true.
							</p>
							<p>
								The best way to do this is to take control, which I can help you
								do.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2>Helping you feel good and in control </h2>
							<p>
								When we wake up each morning, we can all choose to be the master
								of our day or to let our day control us. It’s the difference
								between being proactive or reactive, picking how our day goes vs
								letting circumstances take over.{" "}
							</p>
							<p>
								Taking control of your life is about personal leadership; it’s
								about taking responsibility for everything we do and the areas
								of life we want to change - and doing something about it.{" "}
							</p>
							<p>
								And as with most things in life, if we want change, whether this
								is in our professional or personal lives - we’ll benefit from a
								professional person ‘in our corner’
							</p>
							<p>
								It’s why, while we can all splash paint up a wall, we get
								professional decorators in to do the job. It’s why, if we’re on
								a health kick, we hire a personal trainer - they’re experts who
								can help us get to where we want to be quicker while avoiding
								the pitfalls that only come with experience.{" "}
							</p>
							<p>
								And again, it’s why professional athletes have coaches; like
								you, on paper, they may well be world-class, but they still need
								a coach to push, motivate, hold them accountable and keep them
								at the top of their game.{" "}
							</p>
							<p>
								This is where my life coaching comes into play, to help you
								overcome your circumstances, achieve your dreams, hit your goals
								and stay on top of your game.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Professional Life Coaching </h2>
							<p>
								As your life coach, it’s my job to help you maximise your full
								potential and reach your desired life outcome. Like a supportive
								friend and a trusted adviser rolled into one. Someone who will
								push you to identify your goals, hold you accountable and
								encourage you on your journey to becoming a better version of
								yourself.
							</p>
							<p>
								No matter what your desired outcome, most issues come under the
								following headline areas:
							</p>
							<ul>
								<li>Achieving your goals & dreams</li>
								<li>Understanding yourself better</li>
								<li>Building your confidence</li>
								<li>
									Developing a clearer vision for the future and making it
									happen
								</li>
								<li>
									Looking at ways to improve the way you interact with others,
									both at home and at work
								</li>
							</ul>
							<p>
								But ultimately, it’s about you, your values and what you feel
								you need to change to become happy.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right my-3 my-xl-0 ml-xl-3 service-page-body-image"
								src="../../images/jason-client-meeting-5.jpg"
							/>
							<h2>What life coaching isn’t</h2>
							<p>
								We’ve all come across a consultant at one time or other whether
								that’s in our business or private life. Consultants give
								prescriptive advice about what an individual needs to do to
								improve things; which is completely appropriate in some
								circumstances.
							</p>
							<p>
								However, life coaching isn’t about telling you what to do or how
								to live your life. Instead, I will listen carefully to you, ask
								questions, and we’ll explore a number of pathways open to you.
								We’ll then work together to pick the right option that fits your
								values and life goals. Then we can plan, agree your action
								points and I’ll support, encourage and hold you accountable.
							</p>
							<p>
								It’s also not ‘counselling’. While we do discuss your emotions,
								how you feel and how they could be enhancing or self-limiting,
								it’s not a traditional counselling service where we look back
								into your past to find the root of an issue. This is
								forward-looking, outcome-driven coaching.{" "}
							</p>
							<p>
								And lastly, life coaching isn’t about judging or critiquing your
								actions or choices. It’s confidential, non-judgemental coaching
								sessions between two adults with a shared goal - helping you get
								back on track or find a new pathway.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2>Life Coaching - How can I help you?</h2>
							<p>
								As you can see, my name’s Jason Cornes, and I’m a life and small
								business coach.{" "}
							</p>
							<p>
								Based on the Sussex Coast, my job is split between providing
								life coaching to individuals from all walks of life, and
								coaching and mentoring self-employed business owners.
							</p>
							<p>
								As you can imagine there is a crossover, for example, where
								business owners require a more life coaching approach or life
								coaching clients want to explore business ideas or talk about
								their careers.
							</p>
							<p>
								While every client is different, with their own unique
								challenges and difficulties, whether it’s business or life
								coaching, they all have the same desire. They want to make
								positive changes, to improve their lot, and make things easier
								and happier.{" "}
							</p>
							<p>Certain things underpin my coaching services:</p>
							<ul>
								<li>
									A lifetime of personal learning and a passionate desire to
									help others
								</li>
								<li>
									At 55 years old I am happier, less stressed and more content
									than ever before
								</li>
								<li>
									I am in my element with my work. A meeting together of my
									interests, values, personality, natural talents and learned
									skills.
								</li>
								<li>
									My business background and qualifications (DMS and MBA) - I’ve
									been self-employed my whole life.
								</li>
								<li>
									Alongside my five years of training in counselling and
									psychotherapy{" "}
								</li>
							</ul>
							<p>
								My counselling training above all else taught me how to listen.
								Our work together will no doubt help us both to understand your
								personality, why you do the things you do, the way you do them
								and how you see the world, alongside providing insights into how
								we can maximise your strengths while uncovering what’s really
								holding you back from where you want to in life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">How life coaching works </h2>
							<p>
								When it comes to life coaching, it’s all about you, with bespoke
								coaching sessions that aim to address what’s most important to
								you, at a pace you’re most comfortable with, in a trusting, safe
								and non-judgemental environment.{" "}
							</p>
							<p>
								After an introductory call, we will discuss and decide how often
								we’ll meet, how long each session will be, and what
								communication method works best for you - whether this is in
								person or remotely via Skype/Zoom, alongside what email or
								telephone support you’ll require between sessions.
							</p>
							<p>Throughout our sessions, we will work together to:</p>
							<ul>
								<li>Decide what it is you most want to achieve and when.</li>
								<li>Explore and analyse your current situation.</li>
								<li>
									Identify possible roadblocks to success, whether these are
									real or imagined.
								</li>
								<li>
									Design measurable, attainable goals and a time-based action
									plan to get you to where you want to be.
								</li>
							</ul>
						</Col>
					</Row>
					<Row>
						<HorizontalTestimonial
							review="Jason mentored me for a year when I first launched my community interest company Volunteers Network. He helped me to prioritise my workload, develop a work/life balance and also develop a business plan that has seen our company grow exponentially over the last five years, supporting all sorts of community initiatives. The focus he helped me to develop was invaluable. Helen"
							name="Helen Burton"
							profilePic={helen}
							showLinkedIn="d-none"
							linkedIn="https://www.linkedin.com/in/helen-burton-3a7569146/"
						/>
						<HorizontalTestimonial
							review="Wonderful skill-base, knowledge and contact. Exceptional listening skills and really focused on the direction of me and my social enterprise. I strongly recommend Jason."
							name="Luke Watts"
							showLinkedIn="d-none"
							profilePic={luke}
						/>
						<HorizontalTestimonial
							review="In my six months working with Jason I have learned so much. How to be kind with myself for past mistakes or when I could have done things better. I now focused on who I am now, rather than on the person that I was back then. I feel that I have turned a new page and started a fresh chapter in my life. For that, I am so thankful. Jason has opened my eyes to the fact that I have been a judgmental person. Now each time I catch myself judging someone else, I stop that thought and replace it with another way of looking at things. In general I feel much better and my anxiety has reduced. Self-confidence is up! I highly recommend life coaching with Jason."
							name="Sophie Brown"
							showLinkedIn="d-none"
							profilePic={sophie}
						/>
					</Row>
				</Container>
			</section>
			<section className="pb-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">How much does Life Coaching Cost?</h2>
							<p>
								Each and every one of my clients are different and you’ll be no
								exception. The investment depends upon why you have come to life
								coaching, how often we meet, the support you require between
								meetings and how long we work together. We need a discovery
								meeting to discuss the most appropriate way forward to get to
								where you want to be. Here is a guide to pricing:
							</p>
							<ul>
								<li>
									Single ‘one off’ problem buster sessions 1.5 hours £195 each
								</li>
								<li>
									3 months package - regular meetings as required (up to 5 hours
									support per month) and support between meetings £1170
								</li>
								<li>
									6 and 12 months VIP packages available too at £2340 and £4680
									respectively.
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2>Ready to feel in control of your life once more? </h2>
							<p>
								Remember, living a fulfilled, happy and successful life is about
								taking control. Now is your chance to take the action you need
								to turn things around and get you to where you want to be.{" "}
							</p>
							<p>
								If you don’t, chances are you’ll be in the same position this
								time next year. So, if you want to make progress with positive
								change then please get in touch today and we’ll book in a
								discovery call.
							</p>
							<p>I look forward to working with you.</p>
						</Col>
					</Row>
					<Row>
						<Col className="text-center">
							<Button
								className="cta-btn cta-btn-gtm"
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default AboutPage;
